import React from 'react';

import Meta from '../../../components/meta';

import { Link } from 'gatsby';

import Contents from '../../../components/contents';

import SidebarRecruit from '../../../components/sidebarRecruit';

import SidebarJobs from '../../../components/sidebarJobs';

const Backend = () =>{



    return(
        <>

            <Meta
                title="バックエンドエンジニア"
                description="私たちと一緒に「受発注業務を変えるしくみ」を創りましょう"
            />

            <Contents>

                <div className="c-sub-bg is-backend">

                    <div className="c-sub-header">
                        <em className="c-sub-header--eng u-josef">
                        BACKEND<br />
                        ENGINEER
                        </em>

                        <h1 className="c-sub-header--title">
                        私たちと一緒に「受発注業務を変えるしくみ」を創りましょう
                        </h1>
                    </div>

                </div>



                <article className="p-recruit-jobs">

                    <section className="p-recruit-jobs-section">

                        <div className="p-recruit-section-box is-detail">
                            <div className="p-recruit-job">
                                <p className="p-recruit-job--text">
                                募集内容
                                </p>
                            </div>

                            <div className="p-recruit-cat">
                                <p className="p-recruit-cat--text">
                                REQUIRE
                                </p>
                            </div>

                            <div className="p-recruit-data">

                                <div className="p-recruit-data-box">
                                    <p className="p-recruit-data-box--title">
                                    この仕事について
                                    </p>

                                    <div className="p-recruit-data-box-cnt">
                                        <p className="p-recruit-data-box-cnt--text">
                                        スマホ・タブレットで受発注業務がおこなえる、モバイルWeb受発注システム「MOS」は412社、25万人を超える稼働実績を持つサービスとなりました。
                                        今後も多くのお客様のご要望に応えるため、MOSのアップデート、新機能、個別のカスタマイズをおこなっていく必要があります。
                                        そのためのアイディアや開発を進めるのがバックエンドエンジニアの業務になります。また良質なITエンジニアの育成も推進しており、幅広く開発案件を担当しつつ技術力向上を目指すエンジニアを募集します。
                                        </p>
                                    </div>
                                </div>

                                <div className="p-recruit-data-box">
                                    <p className="p-recruit-data-box--title">
                                    主な仕事内容
                                    </p>

                                    <div className="p-recruit-data-box-cnt">
                                        <p className="p-recruit-data-box-cnt--text">
                                        「MOS」の開発プロジェクトメンバーとして、開発案件をご担当いただきます。
                                        要件定義などの上流工程から、基本設計、詳細設計、実装、テストまで、経験やスキルを考慮しプロジェクトに参画していただきます。
                                        経験が浅い方の場合、既存メンバーとの共同作業、開発経験を積むことでスキルアップをおこなっていただきます。その場合、安心して業務に取り組めるようバックアップがございますので、ご安心ください。
                                        </p>
                                    </div>
                                </div>

                                <div className="p-recruit-data-box">
                                    <p className="p-recruit-data-box--title">
                                    必要な経験・スキル
                                    </p>

                                    <div className="p-recruit-data-box-cnt">
                                        <ul>
                                            <li>
                                                Webアプリケーション開発の実務経験
                                            </li>

                                            <li>
                                                バージョン管理ツールを使った開発の経験
                                            </li>

                                            <li>
                                                フレームワークを用いた開発の経験
                                            </li>

                                            <li>
                                            【SE必須】何らかのシステム開発における基本設計or詳細設計のご経験
                                            </li>

                                            <li>
                                            【PG必須】何らかのオープン言語(Java/PHP/C#/C++)を用いた開発経験
                                            </li>
                                        </ul>

                                        <p className="p-recruit-data-box-cnt--text">
                                        現在「MOS」の開発はPHPですが、PHPの経験は必須ではなく、他言語においてWebアプリケーション開発の経験をお持ちの方であれば問題ありません。
                                        また必須ではありませんが、下記のスキルをお持ちの方は弊社でより活躍ができるかと思います。
                                        </p>

                                        <ul>
                                            <li>
                                            古いコードを読み解いて改善していける
                                            </li>

                                            <li>
                                            変更に強い設計・実装ができる
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="p-recruit-data-box">
                                    <p className="p-recruit-data-box--title">
                                    歓迎する経験・スキル
                                    </p>

                                    <div className="p-recruit-data-box-cnt">
                                        <ul>
                                            <li>
                                                プロジェクトマネージャー、リーダーの経験
                                            </li>

                                            <li>
                                                他社システムとの連携・基幹システムとの連携などの経験
                                            </li>

                                            <li>
                                                外部APIを利用したシステム開発経験
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="p-recruit-data-box">
                                    <p className="p-recruit-data-box--title">
                                    求める人材
                                    </p>

                                    <div className="p-recruit-data-box-cnt">
                                        <ul>
                                            <li>
                                            コミュニケーションがとれる
                                            </li>

                                            <li>
                                            向上心がある・新しいことにチャレンジしたい
                                            </li>

                                            <li>            
                                            お客様の課題解決に取り組める
                                            </li>

                                            <li>
                                            わからないことを声に出していける
                                            </li>

                                            <li>
                                            自発的に行動できる
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="p-recruit-data-box">
                                    <p className="p-recruit-data-box--title">
                                    開発環境
                                    </p>

                                    <div className="p-recruit-data-box-cnt">
                                        <p className="p-recruit-data-box-cnt--text">
                                        <span className="is-caption">開発言語</span>
                                        PHP、JavaScript、SQL等
                                        </p>

                                        <p className="p-recruit-data-box-cnt--text">
                                        <span className="is-caption">フレームワーク</span>
                                        FuelPHP、他各種フレームワーク
                                        </p>

                                        <p className="p-recruit-data-box-cnt--text">
                                        <span className="is-caption">ツール</span>
                                        Visual Studio Code、Eclipse等
                                        </p>

                                        <p className="p-recruit-data-box-cnt--text">
                                        <span className="is-caption">インフラ</span>
                                        クラウド系（AWS等）
                                        </p>

                                        <p className="p-recruit-data-box-cnt--text">
                                        <span className="is-caption">バージョン管理ツール</span>
                                        TortoiseSVN等
                                        </p>
                                    </div>
                                </div>

                                <div className="p-recruit-data-box">
                                    <p className="p-recruit-data-box--title">
                                    学歴
                                    </p>

                                    <div className="p-recruit-data-box-cnt">
                                        <p className="p-recruit-data-box-cnt--text">
                                        高卒以上
                                        </p>
                                    </div>
                                </div>

                            </div>

                            <div className="p-recruit-entry is-single">
                                <Link to="/contact" title="お問い合わせ" className="p-recruit-entry--btn">
                                採用について問い合わせる
                                </Link>
                            </div>

                        </div>

                    </section>



                    <section className="p-recruit-jobs-section">

                        <div className="p-recruit-section-box is-detail">
                            <div className="p-recruit-job">
                                <p className="p-recruit-job--text">
                                給与・待遇
                                </p>
                            </div>

                            <div className="p-recruit-cat">
                                <p className="p-recruit-cat--text">
                                SALARY / COMPENSATION
                                </p>
                            </div>

                            <div className="p-recruit-data">

                                <div className="p-recruit-data-box">
                                    <p className="p-recruit-data-box--title">
                                    雇用形態
                                    </p>

                                    <div className="p-recruit-data-box-cnt">
                                        <p className="p-recruit-data-box-cnt--text">
                                        正社員
                                        </p>
                                    </div>
                                </div>

                                <div className="p-recruit-data-box">
                                    <p className="p-recruit-data-box--title">
                                    給与
                                    </p>

                                    <div className="p-recruit-data-box-cnt">
                                        <p className="p-recruit-data-box-cnt--text">
                                        都度確認・応相談
                                        </p>

                                        <p className="p-recruit-data-box-cnt--text">
                                        経験・能力を考慮して決定いたします
                                        </p>
                                    </div>
                                </div>

                                <div className="p-recruit-data-box">
                                    <p className="p-recruit-data-box--title">
                                    勤務地
                                    </p>

                                    <div className="p-recruit-data-box-cnt">
                                        <p className="p-recruit-data-box-cnt--text">
                                        金沢本社<br />
                                        〒920-0022 石川県金沢市北安江3丁目6番6号 メッセヤスダ1F/2F
                                        </p>

                                        <p className="p-recruit-data-box-cnt--text">
                                        東京事務所<br />
                                        〒102-0083 東京都千代田区麹町5-3-23 日テレ四谷ビル
                                        </p>

                                        <p className="p-recruit-data-box-cnt--text">
                                        大阪事務所<br />
                                        〒530-0017 大阪府大阪市北区角田町8-1 大阪梅田ツインタワーズ・ノース 23F
                                        </p>
                                    </div>
                                </div>

                                <div className="p-recruit-data-box">
                                    <p className="p-recruit-data-box--title">
                                    制度・福利厚生
                                    </p>

                                    <div className="p-recruit-data-box-cnt">
                                        <ul>
                                            <li>
                                            在宅勤務（全従業員利用可）
                                            </li>
                                            <li>
                                            従業員持株会制度
                                            </li>
                                            <li>
                                            資格取得奨励金制度
                                            </li>
                                            <li>
                                            慶弔見舞金制度
                                            </li>
                                            <li>
                                            フレックスタイム制度
                                            </li>
                                            <li>
                                            PC支給
                                            </li>
                                            <li>
                                            服装自由（一部従業員利用可）
                                            </li>
                                            <li>
                                            オフィス内フリーアドレス
                                            </li>
                                            <li>
                                            誕生日休暇
                                            </li>
                                            <li>
                                            健康診断（年1回）
                                            </li>
                                            <li>
                                            インフルエンザ予防接種支援
                                            </li>
                                            <li>
                                            書籍購入補助
                                            </li>
                                            <li className="is-end">
                                            など
                                            </li>
                                        </ul>

                                        <div className="c-link">
                                            <Link to="/recruit/culture" title="アクロスの成長支援について知る" className="c-link--btn">
                                            アクロスの成長支援について知る<span className="c-link--cursor"></span>
                                            </Link>
                                        </div>

                                    </div>
                                </div>

                                <div className="p-recruit-data-box">
                                    <p className="p-recruit-data-box--title">
                                    諸手当
                                    </p>

                                    <div className="p-recruit-data-box-cnt">
                                        <p className="p-recruit-data-box-cnt--text">
                                        会社規程に基づき支給（実費支給 上限あり）
                                        </p>
                                    </div>
                                </div>

                                <div className="p-recruit-data-box">
                                    <p className="p-recruit-data-box--title">
                                    就業時間
                                    </p>

                                    <div className="p-recruit-data-box-cnt">
                                        <p className="p-recruit-data-box-cnt--text">
                                        フレックスタイム制<br />
                                        フレキシブルタイム 始業6：00～10：00　終業15：00～19：00<br />
                                        コアタイム 10:00～15:00
                                        休憩：１時間
                                        </p>
                                        <p className="p-recruit-data-box-cnt--text">
                                        時間外勤務 月平均5時間、固定残業代制（月45時間を超える時間外労働は追加で支給）
                                        </p>
                                    </div>
                                </div>

                                <div className="p-recruit-data-box">
                                    <p className="p-recruit-data-box--title">
                                    試用期間
                                    </p>

                                    <div className="p-recruit-data-box-cnt">
                                        <p className="p-recruit-data-box-cnt--text">
                                        3ヶ月 （試用期間中の勤務条件：変更なし）
                                        </p>
                                    </div>
                                </div>

                                <div className="p-recruit-data-box">
                                    <p className="p-recruit-data-box--title">
                                    社会保険
                                    </p>

                                    <div className="p-recruit-data-box-cnt">
                                        <p className="p-recruit-data-box-cnt--text">
                                        健康保険、厚生年金、雇用保険、労災保険（社会保険完備）
                                        </p>
                                    </div>
                                </div>

                                <div className="p-recruit-data-box">
                                    <p className="p-recruit-data-box--title">
                                    休日
                                    </p>

                                    <div className="p-recruit-data-box-cnt">
                                        <p className="p-recruit-data-box-cnt--text">
                                        年間 124日/完全週休二日制、土曜・日曜・祝日、誕生日休暇、年末年始、有給休暇 （入社半年経過時点10日）、リフレッシュ休暇（年4日）
                                        </p>
                                    </div>
                                </div>

                            </div>

                            <div className="p-recruit-entry is-single">
                                <Link to="/contact" title="お問い合わせ" className="p-recruit-entry--btn">
                                採用について問い合わせる
                                </Link>
                            </div>

                        </div>

                    </section>

                </article>



                <SidebarJobs />



                <SidebarRecruit />



            </Contents>

        </>
    )
}

export default Backend;


